.hl-line1 {
    animation: rotating 4s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
}
.hl-line2 {
    animation: rotating-back 4s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
}
.hl-line3 {
    animation: rotating-back 4s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
}
@keyframes rotating {
    0% {transform: rotate(1080deg);}
    100% {transform: rotate(0deg);}
}
@keyframes rotating-back {
    0% {transform: rotate(0deg);}  
    100% {transform: rotate(1080deg);}
}
.page-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}